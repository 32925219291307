html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-size: 1.5em;
  line-height: 1.6;
  font-weight: 400;
  font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #222;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  font-size: 1.5em;
  line-height: 1.6;
  font-weight: 400;
  font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #222;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button:focus,
input:focus {
  outline: 0;
}
button {
  cursor: pointer;
  background: transparent;
  border: none;
}

ul,
li {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #00853e;
  border-radius: 0;
}
