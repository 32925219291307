.share {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
